import { createRouter, createWebHistory } from "vue-router";

import Home from "../views/Home.vue";

// tela de busca
import Search from "../views/Search.vue";

// filtros
import CollectionPage from '../views/Collection.vue';
import Artists from '../views/Artists.vue';
import Articles from '../views/Articles.vue'
import News from '../views/News.vue';
import Exhibitions from '../views/Exhibitions.vue';

// pagina interna
import TheCollection from '../views/Collections.vue';
import Article from '../views/Article.vue';
import Artist from '../views/Artist.vue';
import Video from '../views/Video.vue';

// about
import About from "../views/about/About.vue";
import Schedule from "../views/about/Schedule.vue";
import Help from "../views/about/Help.vue";
import Careers from "../views/about/Careers.vue";
import Contact from "../views/about/Contact.vue";

const routes = [
    {
      path: "/",
      name: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      name: "Home",
      component: Home
    },

    // paginas internas
    {
      path: "/en/:autor/works/:url",
      name: "The Collection",
      component: CollectionPage
    },
    {
      path: "/en/articles/:url",
      name: "Article",
      component: Article
    },
    {
      path: "/en/:url",
      name: "Artist",
      component: Artist
    },
    {
      path: "/en/videos/:url",
      name: "Video",
      component: Video
    },

    // paginas de filtro
    {
      path: "/search/objects",
      name: "Objects",
      component: TheCollection
    },
    {
      path: "/search/artists",
      name: "Artists",
      component: Artists
    },
    {
      path: "/search/articles",
      name: "Articles",
      component: Articles
    },
    {
      path: "/search/news",
      name: "News",
      component: News
    },
    {
      path: "/search/videos",
      name: "Exhibitions",
      component: Exhibitions
    },
    {
      path: "/search/all/:word",
      name: "Search",
      component: Search
    },

    // about
    {
      path: "/about",
      name: "About",
      component: About
    },
    {
      path: "/schedule-tour",
      name: "ScheduleTour",
      component: Schedule
    },
    {
      path: "/help",
      name: "Help",
      component: Help
    },
    {
      path: "/jobs-opportunities",
      name: "Careers",
      component: Careers
    },
    {
      path: "/Contact",
      name: "Contact",
      component: Contact
    }
]

const router = createRouter({ history: createWebHistory(), routes, linkActiveClass: "active" });

router.beforeEach(() => {
  window.scrollTo(0,0)
})

export default router;