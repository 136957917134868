import { createApp } from 'vue'
import App from './App.vue'

import router from "./router";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'

import './assets/css/app.css'

const appInstance = createApp(App);


appInstance.config.globalProperties.$baseAPI = 'https://dafbeirut.org/en/api/';
appInstance.config.globalProperties.$baseImage = 'https://dafbeirut.org/';


appInstance.use(router);
appInstance.mount("#app");