<template>
  <div class="col-12 my-5 container-center">
    <div class="col-12 fs-2">
      <BtnVoltar />
    </div>
    <div class="col-12">
      <div class="row w-100 mx-auto">
        <div class="input-group mb-3">
          <div class="col-12 fs-4 my-2">Articles Results</div>
          <input type="text" v-model="palavra" class="form-control" placeholder="Search Art, Artists, News & Articles on dafbeirut.org">
          <button class="btn btn-outline-secondary btn-secondary text-white bg-dark" @click="buscar()">Search</button>
        </div>
      </div>
    </div>
    <div class="col-12">
        <div class="row w-100 mx-auto">
          <div class="col-lg-4 col-sm-6 col-12 mt-5" role="button" v-for="item in articles" :key="item.id">
            <router-link :to="item.pageURL" class="text-decoration-none nav-link">
              <img v-bind:src="$baseImage+ item.image" class="img-fluid cropped1">
              <div class="col-12 mt-2">{{ item.name }}</div>
            </router-link>
          </div>
        </div>
    </div>
</div>
</template>

<script>

  import BtnVoltar from '@/components/BtnVoltar.vue'
  import router from '../router'

  export default {
    name: 'ArticlesPage',
    components: { BtnVoltar },
    props: {
      msg: String
    },
    data() {
      return {
        articles: [],
        palavra: null
      }
    }, 
    mounted() {
      this.getObjects()
    },
    methods: {
      async getObjects(){

        let collection = window.localStorage.getItem('articles')
        collection = JSON.parse(collection)

        if (!collection) {
          const req = await fetch(this.$baseAPI + 'articles')
          const data = await req.json()
          this.articles = data
          window.localStorage.setItem('articles', JSON.stringify(data))
        } else {
          this.articles = collection
        }

      },
      async buscar (){
        router.push('/search/all/'+this.palavra)
      }
    }
  }
</script>