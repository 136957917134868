<template>
  <div class="col-12 my-5 container-center mx-auto p-2">
    <div class="col-12 fs-2">
      <BtnVoltar />
    </div>
    <div class="col-12 fs-2">{{ element.name }}</div>
    <div class="col-12 mt-2 fs-4 fst-italic">{{ standfirst }}</div>
    <div class="col-12 fs-6 mt-4">Written by {{ parent_name }}</div>
    <div v-html="description" style="text-align:justify" class="col-12 col-md-12 mt-4 fs-4"></div>
  </div>
</template>

<script>

  import BtnVoltar from '@/components/BtnVoltar.vue'
  export default {
    name: 'ArticlesPage',
    components: { BtnVoltar },
    props: {
      msg: String
    }, data() {
        return {
          element: [],
          parent_name: null,
          country: null,
          standfirst: null,
          dimension: null
        }
      }, mounted () {
        let collection = window.localStorage.getItem('articles')
        collection = JSON.parse(collection)
        
        let url = '/en/articles/'+this.$route.params.url
        let index = collection.findIndex(item => item.pageURL == url)

        this.element = collection[index]
        this.parent_name = collection[index].translation.author
        this.standfirst = collection[index].translation.standfirst
        this.description = collection[index].translation.description
        // this.country = collection[index].page_parent.fields.nationality
        // this.description = collection[index].translation.description
        // console.log(this.description)
      }
  }
</script>