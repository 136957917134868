<template>
  <div class="col-12 my-5 container-center p-2">
    <div class="col-12 fs-2">
      <BtnVoltar />
    </div>
    <div class="col-md-6 col-12 mx-auto">
      <img v-bind:src="$baseImage + element.image" class="img-fluid">
    </div>
    <div class="col-12 col-md-10 mx-auto fs-3 text-golden mt-4">{{ parent_name }}, {{ country }}</div>
    <div class="col-12 col-md-10 mx-auto fs-4">{{ element.name }}, {{ ano }}</div>
    <div class="col-12 col-md-10 mx-auto fs-4">Medium: <span class="text-dourado">{{ medium }}</span></div>
    <div class="col-12 col-md-10 mx-auto mt-4 fs-5">{{ dimension }}</div>
    <div v-html="description" style="text-align:justify" class="col-12 col-md-10 mx-auto mt-4 fs-6"></div>
  </div>
</template>

<script>

import BtnVoltar from '@/components/BtnVoltar.vue'
export default {
  name: 'TheCollection',
  components: { BtnVoltar },
  props: {
    msg: String
  }, data() {
      return {
        element: [],
        parent_name: null,
        country: null,
        ano: null,
        medium: null,
        dimension: null
      }
    }, mounted () {
      let collection = window.localStorage.getItem('objects')
      collection = JSON.parse(collection)

      let url = '/en/'+this.$route.params.autor+'/works/'+this.$route.params.url
      console.log(url)

      let index = collection.findIndex(item => item.pageURL == url)
      this.element = collection[index]
      this.parent_name = collection[index].page_parent.name
      this.country = collection[index].page_parent.fields.nationality
      this.ano = collection[index].fields.yearcreated
      this.dimension = collection[index].fields.dimension1._text
      this.description = collection[index].translation.description
      this.medium = collection[index].tags[2].name
      console.log(collection)
    }
}
</script>