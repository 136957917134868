<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark" style="    padding-top: 60px;">
        <div class="container-fluid">
            <router-link :to="'/'" class="text-decoration-none nav-link">
                <img src="../assets/logo.svg" width="35" />
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <router-link :to="'/search/objects'" class="text-decoration-none nav-link">The Collection</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="'/search/artists'" class="text-decoration-none nav-link">Artists</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="'/search/articles'" class="text-decoration-none nav-link">Articles</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="'/search/news'" class="text-decoration-none nav-link">News</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="'/search/videos'" class="text-decoration-none nav-link">Exhibitions</router-link>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    About DAF
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><router-link :to="'/about'" class="dropdown-item">About Us</router-link></li>
                        <li><router-link :to="'/schedule-tour'" class="dropdown-item">Schedule Tour</router-link></li>
                        <li><router-link :to="'/help'" class="dropdown-item">Help</router-link></li>
                        <li><router-link :to="'/jobs-opportunities'" class="dropdown-item">Careers</router-link></li>
                        <li><hr class="dropdown-divider"></li>
                        <li><router-link :to="'/contact'" class="dropdown-item">Contact</router-link></li>
                    </ul>
                </li>
                </ul>
                <form class="d-flex">
                <input class="form-control me-2" v-model="palavra" type="search" placeholder="Search Art, Artists, News & Articles on dafbeirut.org">
                <button class="btn btn-outline-secondary" @click="buscar()">Search</button>
                </form>
            </div>
        </div>
    </nav>
</template>

<script>

  import router from '../router'
  export default {
    name: 'NavBar',
    data() {
      return {
        palavra: null
      }
    },
    props: {
      msg: String
    },
    methods: {
      async buscar (){
        router.push('/search/all/'+this.palavra)
      }
    }
  }
</script>